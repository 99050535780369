<template>
	<div>
		<div class="material-box">
			<div class="material">
				<div class="between" style="
            padding: 14px 0px;
            border-bottom: 1px solid #dfdfdf;
            margin-bottom: 30px;
          ">
					<div class="align-center" style="color: #c8c8c8">
						<i class="el-icon-location-outline"></i>
						当前位置：首页>IP素材
					</div>

					<div class="Secrch align-center" :class="{ 'Secrch-open': ScachFlag }">
						<div v-if="ScachFlag" class="Secrch-input">
							<input type="text" v-model="SecahDate" />
						</div>
						<div v-if="!ScachFlag" @click="ScachFlag = !ScachFlag">
							<i class="el-icon-search"></i>
							搜索
						</div>
						<div v-if="ScachFlag" @click="SeachS">
							<i class="el-icon-search"></i>
							搜索
						</div>
					</div>
				</div>
				<div class="flex">
					<div>素材分类：</div>
					<div style="margin-right: 20px; margin-left: 40px" :class="{ selectTab_item: SelectTab == 0 }" @click="Selecttabs(0, 0)">
						全部
					</div>
					<div class="flex" style="flex-wrap: wrap">
						<div v-for="(item, index) in tabList" :key="index" class="tabitem" :class="{ selectTab_item: SelectTab == index + 1 }" @click="Selecttabs(item, index + 1)">
							{{ item.title }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="material-tab-footer center-noflex">
			<div class="List_title">素材展示</div>

			<div class="material-list">
				<!-- 布局占位符 请勿删除这三个div -->

				<div v-for="(item, index) in list" :key="index" class="material-item" @click="Godetali(item)">
					<img class="material-item-img" :src="item.image" alt="" />
					<div>
						<div class="material-item-title">{{ item.title }}</div>
						<div class="flex" style="margin-left: 10px">
							<div class="material-item-tag" v-for="(items, indexs) in item.tag" :key="indexs">
								{{ items }}
							</div>
						</div>
						<div class="flex material-Icon end" style="font-size: 12px; color: #c7c7c7">
							<div class="align-center" style="margin-right: 5px">
								<img style="height: 13px; width: 18px; margin-right: 5px" src="@/assets/material/Look_Icon.png" alt="" />
								<div>{{ item.is_look }}</div>
							</div>
							<div class="align-center">
								<img style="height: 14px; width: 15px; margin-right: 5px" src="@/assets/material/like_Icon.png" alt="" />
								<div>{{ item.like }}</div>
							</div>
						</div>
						<div class="align-center item-footer">
							<img :src="item.header_img" alt="" />
							<div>{{ item.power }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="materrial-pagination">
				<el-pagination :page-size="16" :pager-count="pager_count" background layout="prev, pager, next" :total="total" @current-change="changepgae">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "material",
		async created() {
			const {
				data
			} = await this.$api({
				c: "index",
				a: "IP_list",
			});
			//console.log(data);
			this.total = data.count;
			this.page_size=data.page_size;
			this.pager_count=data.pages;
			this.list = data.arr;
			this.tabList = data.cate;
		},
		data() {
			return {
				total: 0,
				SecahDate: "",
				ScachFlag: false,
				SelectTab: 0,
				tabList: [],
				list: [],
				page: 1,
				cate_id: 0,
				pager_count:0,
				page_size:0,
			};
		},
		methods: {
			async SeachS() {
				//console.log(this.SecahDate);
				var cate_id = this.cate_id;
				var page = this.page;
				const {
					data
				} = await this.$api({
					c: "index",
					a: "IP_list",
					seach_title: this.SecahDate,
					page:page,
					cate_id:cate_id
				});
				if (data.count > 0) {
					this.total = data.count;
					this.page_size=data.page_size;
					this.pager_count=data.pages;
					this.list = data.arr;
					this.SecahDate = "";
				}
			},

			async Selecttabs(e, index) {
				this.SelectTab = index;
				var id = 0;
				if (index == 0) {
					id = 0;
				} else {
					id = e.id;
				}
				// 分类切换
				//console.log(e);
				this.page = 1;
				this.cate_id = id;
				const {
					data
				} = await this.$api({
					c: "index",
					a: "IP_list",
					cate_id: id,
				});
				this.list = [];
				this.total = "";
				if (data.count > 0) {
					this.total = data.count;
					this.page_size=data.page_size;
					this.pager_count=data.pages;
					this.list = data.arr;
				} else {
					this.list = [];
					this.total = 0;
					this.page_size=0;
					this.pager_count=0;
				}
			},
			changepgae(e) {
				//console.log(e);
				this.page=e;
				this.SeachS();
				// 页数切换
			},
			Godetali(e) {
				this.$router.push({
					path: "/index/IPindexDetail",
					query: {
						id: e.id,
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.material-box {
		background-color: #ffffff;
		padding-bottom: 36px;

		.material {
			width: 1200px;
			margin: 0 auto;
		}
	}

	.GoSettle {
		border-radius: 100px 100px 100px 0px;
		background-color: rgb(253, 71, 1);
		padding: 7px 16px;
		margin-left: 20px;
	}

	.tabitem {
		margin-bottom: 5px;
		margin-right: 40px;
		cursor: pointer;
	}

	.Secrch-open {
		width: 400px !important;
		display: flex;

		.Secrch-input {
			flex: 1;

			input {
				width: 100%;
				height: 100%;
			}
		}
	}

	.Secrch {
		width: 100px;
		border-radius: 100px;
		padding: 4px 24px;
		border: 1px solid rgb(0, 118, 254);
		color: rgb(0, 118, 254);
		transition: all 0.5s;

		i {
			margin-right: 5px;
		}
	}

	.selectTab_item {
		color: rgb(0, 118, 254);
	}

	.material-list {
		width: 1200px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		//   display: grid;
		//   grid-template-columns: 283px 283px 283px 283px;
		//   grid-column-gap: 23px;
	}

	.material-tab-footer {
		background-color: #f8f8f8;
	}

	.material-item-tag {
		padding: 0px 4px;
		margin-right: 4px;
		border-radius: 50px;
		font-size: 10px;
		border: 1px solid rgb(0, 118, 254);
		color: rgb(0, 118, 254);
	}

	.material-Icon {
		margin-top: 28px;
		padding-bottom: 16px;
		border-bottom: 1px solid #ececec;
	}

	.material-item-title {
		font-weight: 100;
		font-size: 20px;
		padding-left: 20px;
		margin-bottom: 5px;
	}

	.material-item {
		width: 283px;
		background-color: #fff;
		border-bottom: 2px solid #ffffff;
		transition: all 0.8s;
		margin-bottom: 23px;

		&:hover {
			border-bottom: 2px solid rgb(0, 118, 254);
		}

		.item-footer {
			color: #676767;
			font-size: 12px;

			padding: 14px 12px;

			img {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				margin-right: 5px;
			}
		}
	}

	.materrial-pagination {
		margin: 43px 0 50px;
	}

	.List_title {
		width: 1200px;
		margin-top: 23px;
		margin-bottom: 26px;
		color: #676767;
		text-align: start;
	}

	.material-item-img {
		object-fit: cover;
		width: 100%;
		height: 300px;
	}
</style>
